import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import dateFormated from "../../tools/dateFormated";
import { autoCloseMsg } from "../../tools/messagesUtils";
import PdfButton from "../../tools/PdfButton";
import { triByCompetenceName, triByName } from "../../tools/sortUtils";

export default function ViewSMEC({ suiviSelected }) {
  const { url } = useContext(AppContext);

  const _NOTES = [
    { value: 0, color: "text-danger" },
    { value: 1, color: "text-warning" },
    { value: 2, color: "text-green-light" },
    { value: 3, color: "text-success" },
  ];

  const [blocs, setBlocs] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    if (suiviSelected !== null) {
      loadBlocs();
      setLoaded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suiviSelected]);

  const loadBlocs = () => {
    let uri =
      url + "api/bloc_afests?formation=" + suiviSelected.promo.formation;
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setBlocs(res["hydra:member"].sort(triByName));
        setLoaded(true);
      } else {
        setLoaded(true);
        setMsg({
          txt: "Erreur de chargement des blocs de compétence",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  return (
    <div
      className="modal fade"
      id="ModalViewSuiviSMEC"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header bg-info text-white">
            <h5 className="modal-title">
              <FontAwesomeIcon icon="clipboard-list" /> &nbsp; Consultation du
              suivi de{" "}
              {suiviSelected !== null
                ? suiviSelected.eleve.user.name +
                  " " +
                  suiviSelected.eleve.user.firstName
                : null}
            </h5>
            <button
              type="button"
              className="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {!loaded ? (
            <div className="pb-5 modal-body text-info">
              <Spinner />
            </div>
          ) : (
            <div className="modal-body mb-3">
              {msg !== null ? (
                <MsgAlert
                  msg={msg.txt}
                  type={msg.type}
                  close={() => autoCloseMsg(setMsg)}
                />
              ) : null}
              <div className="d-flex">
                <div className="col-lg-3">
                  <h6>
                    <strong>
                      <u>Début</u>
                    </strong>
                  </h6>
                  <span>{dateFormated(suiviSelected.createdAt)}</span>
                </div>
                <div className="col-lg-3">
                  <h6>
                    <strong>
                      <u>Date de validation</u>
                    </strong>
                  </h6>
                  <span>
                    {suiviSelected.validateAt
                      ? dateFormated(suiviSelected.validateAt)
                      : "Non validé"}
                  </span>
                </div>
                <div className="col-lg-4">
                  <h6>
                    <strong>
                      <u>Évaluateur</u>
                    </strong>
                  </h6>
                  <span>
                    {suiviSelected.tuteur
                      ? suiviSelected.tuteur.user.name +
                        " " +
                        suiviSelected.tuteur.user.firstName
                      : "Pas de tuteur"}
                  </span>
                </div>
                <div className="col-lg-2 text-right align-self-center">
                  <PdfButton
                    data={{ suivi: suiviSelected, blocs }}
                    uri="/smec"
                  />
                </div>
              </div>
              <div>
                <form>
                  <table className="mt-3 table table-bordered table-sm table-responsive-md">
                    <thead>
                      <tr>
                        <th></th>
                        <th className="align-top">
                          Subit <br />
                          <span className="text-danger">
                            <FontAwesomeIcon icon="minus" />{" "}
                            <FontAwesomeIcon icon="minus" />
                          </span>
                        </th>
                        <th className="align-top">
                          Exécute <br />
                          <span className="text-warning">
                            <FontAwesomeIcon icon="minus" />
                          </span>
                        </th>
                        <th className="align-top">
                          Maîtrise <br />
                          <span className="text-green-light">
                            <FontAwesomeIcon icon="plus" />
                          </span>
                        </th>
                        <th className="align-top">
                          Maîtrise totale
                          <br />
                          <span className="text-success">
                            <FontAwesomeIcon icon="plus" />{" "}
                            <FontAwesomeIcon icon="plus" />
                          </span>
                        </th>
                        <th className="align-top">Activités</th>
                      </tr>
                    </thead>

                    <tbody>
                      {blocs.map((b, i) => {
                        let notes = suiviSelected.notes.filter(
                          (n) => n.competence.bloc["@id"] === b["@id"]
                        );
                        return notes.length === 0 ? null : (
                          <React.Fragment key={i}>
                            <tr>
                              <th className="text-info" colSpan="6">
                                {b.name}
                              </th>
                            </tr>

                            {notes.sort(triByCompetenceName).map((n, i) => {
                              return (
                                <tr key={i}>
                                  <td className="text-left font-weight-bold">
                                    <span className="h6">
                                      {n.competence.name}
                                    </span>
                                    <br />
                                    <small className="text-muted">
                                      {n.commentaire && (
                                        <em>{n.commentaire}</em>
                                      )}
                                    </small>
                                  </td>
                                  {_NOTES.map((t, it) => {
                                    let check = n.note === t.value;
                                    return (
                                      <td
                                        className={`${
                                          check ? t.color : "text-muted"
                                        } align-middle`}
                                        key={"value" + it}
                                      >
                                        {check ? (
                                          <FontAwesomeIcon icon="check-circle" />
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                    );
                                  })}
                                  <td className="align-middle">
                                    {n.activities || "Pas d'activités"}
                                  </td>
                                </tr>
                              );
                            })}
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </form>
              </div>
              <div className="mt-3 text-left border border-info p-1">
                <h6>
                  <strong>
                    <u>Commentaire général :</u>
                  </strong>
                </h6>
                <p>
                  {suiviSelected.comment
                    ? suiviSelected.comment.split("\n").map((item, key) => {
                        return (
                          <React.Fragment key={key}>
                            {item}
                            <br />
                          </React.Fragment>
                        );
                      })
                    : null}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
