import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import AppContext from "../../Context/AppContext";
import DeleteBddProvider from "../../Providers/DeleteBddProvider";
import GetBddProvider from "../../Providers/GetBddProvider";
import Layout from "../common/Layout";
import MsgAlert from "../common/MsgAlert";
import Spinner from "../common/Spinner";
import EditReunion from "../contents/edits/EditReunion";
import ModifReunion from "../contents/modifs/ModifReunion";
import convertDecimal from "../tools/convertDecimal";
import dateFormated from "../tools/dateFormated";
import heuresFormated from "../tools/heuresFormated";
import heuresFormH from "../tools/heuresFormH";
import { autoCloseMsg } from "../tools/messagesUtils";
import { nl2br } from "../tools/nl2br";
import { triByUserName } from "../tools/sortUtils";

const ReleveHeures = () => {
  const { url, user } = useContext(AppContext);

  let temp = new Date();

  let initDebut = new Date(temp.getFullYear(), temp.getMonth(), 1);

  let initFin = new Date(temp.getFullYear(), temp.getMonth() + 1, 0);

  const [debut, setDebut] = useState(
    new Date(
      initDebut.getFullYear(),
      initDebut.getMonth(),
      initDebut.getDate(),
      -initDebut.getTimezoneOffset() / 60
    )
      .toISOString()
      .split("T")[0]
  );
  const [fin, setFin] = useState(
    new Date(
      initFin.getFullYear(),
      initFin.getMonth(),
      initFin.getDate(),
      -initFin.getTimezoneOffset() / 60
    )
      .toISOString()
      .split("T")[0]
  );
  const [actif, setActif] = useState(true);
  const [formateurs, setFormateurs] = useState(null);
  const [formateurSelected, setFormateurSelected] = useState(null);
  const [siteSelected, setSiteSelected] = useState(null);
  const [progressions, setProgressions] = useState(null);
  const [reunions, setReunions] = useState(null);
  const [reunionSelected, setReunionSelected] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [maj, setMaj] = useState(false);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    if (user.role <= 3) {
      setSiteSelected(user.sites[0]["@id"]);
    } else {
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (siteSelected !== null) {
      setLoaded(false);
      load(siteSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteSelected]);

  useEffect(() => {
    setLoaded(false);
    load(siteSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actif]);

  useEffect(() => {
    if (maj === true) {
      setLoaded(false);
      setReunions(null);
      setReunionSelected(null);
      loadProg();
      setMaj(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maj]);

  useEffect(() => {
    if (formateurSelected !== null) {
      setLoaded(false);
      if (reunions !== null) {
        setReunions(null);
      }
      loadProg();
    } else {
      setProgressions(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formateurSelected]);

  const loadReunion = () => {
    if (
      siteSelected !== null ||
      (formateurSelected !== null && formateurSelected !== "")
    ) {
      let uriR =
        url +
        "api/reunions?order[createdAt]=asc&createdAt[after]=" +
        debut +
        "&createdAt[before]=" +
        fin +
        "&";
      if (formateurSelected === null || formateurSelected === "") {
        uriR += "site=" + siteSelected;
      } else {
        let userId;
        formateurs.forEach((f) => {
          if (f.id === parseInt(formateurSelected)) {
            userId = f.user["@id"];
          }
        });
        uriR += "participants.user=" + userId;
      }
      GetBddProvider(uriR).then((res) => {
        setLoaded(true);
        if (typeof res === "object") {
          setReunions(res["hydra:member"]);
        } else {
          setMsg({
            txt: "Erreur de chargement des réunions",
            type: "danger",
          });
          autoCloseMsg(setMsg, 5000);
        }
      });
    }
  };

  const load = (uriSite) => {
    let uri = `${url}api/formateurs?`;
    if (!uriSite) {
      uri += "user=" + user["@id"];
    } else {
      uri += "user.sites=" + uriSite;
      if (actif) {
        uri += "&user.actif=true";
      }
    }

    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setFormateurs(res["hydra:member"].sort(triByUserName));
        if (user.role > 3 && user.role <= 4) {
          setFormateurs(res["hydra:member"]);
          setFormateurSelected(res["hydra:member"][0].id);
          setLoaded(true);
        } else {
          setFormateurSelected(null);
          loadReunion();
        }
      } else {
        setMsg({ txt: "Erreur de Chargement des formateurs", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadProg = () => {
    if (formateurSelected === null || formateurSelected === "") {
      loadReunion();
    } else {
      let uri =
        url +
        "api/progressions?order[createdAt]=asc&createdAt[after]=" +
        debut +
        "&createdAt[before]=" +
        fin +
        "&formateur.id=" +
        formateurSelected +
        "&";
      user.sites.forEach((site, i, sites) => {
        uri += sites.length > 1 ? "promos.site[]=" : "promos.site=";
        uri += site["@id"];
        uri += i + 1 < sites.length ? "&" : "";
      });
      GetBddProvider(uri).then((res) => {
        if (typeof res === "object") {
          setProgressions(res["hydra:member"]);
          loadReunion();
        } else {
          setLoaded(true);
          setMsg({
            txt: "Erreur de chargement des progressions",
            type: "danger",
          });
          autoCloseMsg(setMsg, 5000);
        }
      });
    }
  };

  const delReunion = (iri) => {
    setLoaded(false);
    let uri = url + iri.slice(1);
    DeleteBddProvider(uri).then((res) => {
      setReunions(null);
      loadProg();
      if (res === 204) {
        setMsg({
          txt: "La réunion est supprimée !",
          type: "success",
        });
        autoCloseMsg(setMsg, 5000);
      } else {
        setMsg({
          txt: "Erreur lors de la suppression",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const validate = () => {
    return debut !== null && fin !== null;
  };

  const AllFormateurs = () => {
    return formateurs.map((f, i) => (
      <div className="col-md-3 p-2" key={i}>
        <div
          className=" border border-info d-flex align-items-center justify-content-center p-1"
          onClick={() => {
            setReunions(null);
            setFormateurSelected(f.id);
          }}
          style={{ cursor: "pointer", minHeight: "60px" }}
        >
          <span>
            {f.user.name} {f.user.firstName}
          </span>
        </div>
      </div>
    ));
  };

  const RelFormateur = () => {
    let formateur;
    formateurs.forEach((f) => {
      if (f.id === parseInt(formateurSelected)) {
        formateur = f;
      }
    });
    return formateur !== undefined &&
      progressions !== null &&
      reunions !== null ? (
      <div className="animFadeIn">
        {progressions.length < 1 && reunions.length < 1 ? (
          <h4 className="text-danger m-4">
            <strong>Pas d'intervention pour cette période</strong>
          </h4>
        ) : null}
        <div>
          <div className="mb-4">
            <ReactHTMLTableToExcel
              className="btn btn-outline-primary"
              table="tableHeure"
              filename={
                "relevé-" +
                formateur.user.name +
                "-" +
                formateur.user.firstName +
                "-" +
                (new Date(debut).getMonth() + 1) +
                "-" +
                new Date(debut).getFullYear()
              }
              sheet="Export"
              buttonText="Export Excel"
            />
          </div>

          <table
            className="table table-sm table-bordered table-responsive-md p-4"
            id="tableHeure"
          >
            <thead>
              <tr>
                <th className="text-primary" colSpan="6">
                  <h2>
                    {formateur.user.name} {formateur.user.firstName}
                  </h2>
                  du {dateFormated(debut)} au {dateFormated(fin)}
                </th>
              </tr>
            </thead>
            {user.sites.map((site, i) => {
              let totalH = 0;
              let coursH = 0;
              let reunionH = 0;
              if (
                formateur.user.sites.filter((s) => s["@id"] === site["@id"])
                  .length === 0
              ) {
                return null;
              } else {
                return (
                  <React.Fragment key={i}>
                    <thead>
                      <tr>
                        <th colSpan="6" className="text-white bg-primary">
                          <h4>{site.name}</h4>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th colSpan="6">
                          <h4>Cours</h4>
                        </th>
                      </tr>
                      <tr className="text-primary">
                        <th>Dates</th>
                        <th>Promos</th>
                        <th>Matières</th>
                        <th>Progressions</th>
                        <th>description</th>
                        <th>Heures</th>
                      </tr>
                      {progressions.filter(
                        (prog) => prog.promos[0].site === site["@id"]
                      ).length < 1 ? (
                        <tr>
                          <td colSpan="5">Pas d'intervention sur la période</td>
                        </tr>
                      ) : (
                        <React.Fragment>
                          {progressions
                            .filter(
                              (prog) => prog.promos[0].site === site["@id"]
                            )
                            .map((prog, i) => {
                              let h = parseFloat(heuresFormated(prog.time));
                              totalH += h;
                              coursH += h;
                              return (
                                <tr key={i}>
                                  <td>{dateFormated(prog.createdAt)}</td>
                                  <td>
                                    {prog.promos.map((p, i, t) =>
                                      t.length > 1 && i + 1 < t.length
                                        ? p.name + ", "
                                        : p.name
                                    )}
                                  </td>
                                  <td className="text-left">
                                    {prog.progressionItem[0].matiere.name}
                                  </td>
                                  <td className="text-left">
                                    {prog.progressionItem[0].name}
                                  </td>
                                  <td className="text-left font-small">
                                    {prog.description &&
                                      nl2br(prog.description)}
                                  </td>
                                  <td>{convertDecimal(h)}</td>
                                </tr>
                              );
                            })}
                          <tr className="text-primary">
                            <td
                              colSpan="5"
                              className="text-right font-weight-bold"
                            >
                              Sous-total
                            </td>
                            <td className="font-weight-bold">
                              {convertDecimal(coursH)}
                            </td>
                          </tr>
                        </React.Fragment>
                      )}
                      <tr>
                        <th colSpan="6">
                          <h4>Réunions/Conseils</h4>
                        </th>
                      </tr>
                      <tr className="text-primary">
                        <th>Dates</th>
                        <th colSpan="4">Objets</th>
                        <th>Heures</th>
                      </tr>
                      {reunions.filter((r) => r.site["@id"] === site["@id"])
                        .length < 1 ? (
                        <tr>
                          <th colSpan="6">
                            Pas de réunion/conseil sur la période
                          </th>
                        </tr>
                      ) : (
                        <React.Fragment>
                          {reunions
                            .filter((r) => r.site["@id"] === site["@id"])
                            .map((r, i) => {
                              let userId;
                              formateurs.forEach((f) => {
                                if (f.id === parseInt(formateurSelected)) {
                                  userId = f.user["@id"];
                                }
                              });
                              let time;
                              r.participants.forEach((part) => {
                                if (part.user["@id"] === userId) {
                                  time = part.time;
                                }
                              });
                              let h = parseFloat(heuresFormated(time));
                              totalH += h;
                              reunionH += h;

                              return (
                                <tr key={i}>
                                  <td>{dateFormated(r.createdAt)}</td>
                                  <td className="text-left" colSpan="3">
                                    {r.label}
                                  </td>
                                  <td>{convertDecimal(h)}</td>
                                </tr>
                              );
                            })}
                          <tr className="text-primary">
                            <td
                              colSpan="5"
                              className="text-right font-weight-bold"
                            >
                              Sous-total
                            </td>
                            <td className="font-weight-bold">
                              {convertDecimal(reunionH)}
                            </td>
                          </tr>
                        </React.Fragment>
                      )}
                      <tr className="text-primary">
                        <th colSpan="5" className="text-right">
                          <h4>Total des Heures pour {site.name}</h4>
                        </th>
                        <th>
                          <h4>{convertDecimal(totalH)}</h4>
                        </th>
                      </tr>
                    </tbody>
                  </React.Fragment>
                );
              }
            })}
          </table>
        </div>
      </div>
    ) : null;
  };

  return Layout(
    <div className="mt-4">
      <div className="d-flex flex-wrap align-items-center flex-column flex-md-row justify-content-md-between justify-content-center justify-content-center mx-4">
        <h2>Relevé d'heures</h2>
        {user.role <= 3 && user.sites.length > 1 && (
          <div className="form-group my-2">
            <select
              className="form-control rounded-pill"
              value={siteSelected || ""}
              onChange={(e) => {
                setSiteSelected(e.target.value);
              }}
            >
              {user.sites.map((s, i) => (
                <option value={s["@id"]} key={i}>
                  {s.name}
                </option>
              ))}
            </select>
          </div>
        )}
        <div>
          {user.role <= 3 ? (
            <button
              className="btn btn-info"
              data-toggle="modal"
              data-target="#SaisiReunion"
            >
              <FontAwesomeIcon icon="plus-circle" />
              &nbsp; Réunion/Conseil
            </button>
          ) : null}
        </div>
      </div>
      {user.role <= 3 ? <EditReunion setMaj={setMaj} /> : null}
      {user.role <= 3 ? (
        <ModifReunion
          reunion={reunionSelected}
          formateurs={formateurs}
          setMaj={setMaj}
          msg={msg}
          setMsg={setMsg}
        />
      ) : null}
      {msg !== null ? (
        <MsgAlert
          msg={msg.txt}
          type={msg.type}
          close={() => autoCloseMsg(setMsg)}
        />
      ) : null}
      <hr className="m-4" />
      {!loaded || formateurs === null ? (
        <Spinner />
      ) : (
        <div>
          <div className="form-row mx-4">
            <div className="form-group col-lg-4">
              <label>Formateur</label>
              <select
                className="form-control"
                value={formateurSelected || ""}
                onChange={(e) => {
                  setReunions(null);
                  setFormateurSelected(e.target.value);
                }}
                disabled={user.role > 3 && user.role <= 4}
              >
                <option value="">Tous les formateurs</option>
                {formateurs.map((f, i) => (
                  <option value={f.id} key={i}>
                    {f.user.name} {f.user.firstName}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group col-lg-3">
              <label>
                Début de période{" "}
                {navigator.userAgent.indexOf("Safari") >= 1 &&
                navigator.userAgent.indexOf("Chrome") <= 0 ? (
                  <em>
                    <small> (aaaa-mm-jj)</small>
                  </em>
                ) : null}
              </label>
              <input
                className="form-control"
                type="date"
                placeholder={
                  navigator.userAgent.indexOf("Safari") >= 1 &&
                  navigator.userAgent.indexOf("Chrome") <= 0
                    ? "aaaa-mm-jj"
                    : null
                }
                pattern={
                  navigator.userAgent.indexOf("Safari") >= 1 &&
                  navigator.userAgent.indexOf("Chrome") <= 0
                    ? "\\d{4}-\\d{2}-\\d{2}"
                    : null
                }
                value={debut || ""}
                onChange={(e) => setDebut(e.target.value)}
                required
              />
            </div>
            <div className="form-group col-lg-3">
              <label>
                Fin de période{" "}
                {navigator.userAgent.indexOf("Safari") >= 1 &&
                navigator.userAgent.indexOf("Chrome") <= 0 ? (
                  <em>
                    <small> (aaaa-mm-jj)</small>
                  </em>
                ) : null}
              </label>
              <input
                className="form-control"
                type="date"
                placeholder={
                  navigator.userAgent.indexOf("Safari") >= 1 &&
                  navigator.userAgent.indexOf("Chrome") <= 0
                    ? "aaaa-mm-jj"
                    : null
                }
                pattern={
                  navigator.userAgent.indexOf("Safari") >= 1 &&
                  navigator.userAgent.indexOf("Chrome") <= 0
                    ? "\\d{4}-\\d{2}-\\d{2}"
                    : null
                }
                value={fin || ""}
                onChange={(e) => setFin(e.target.value)}
                required
              />
            </div>
            <div className="form-group col-lg-2 align-self-end">
              <button
                className="btn btn-outline-primary form-control"
                onClick={() => {
                  setLoaded(false);
                  loadProg();
                }}
                disabled={!validate()}
              >
                Afficher
              </button>
            </div>
          </div>
          {user.role <= 3 && (
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                checked={actif || false}
                onChange={(e) => setActif(!actif)}
              />
              <label className="form-check-label">
                <strong>Formateurs actifs</strong>
              </label>
            </div>
          )}
          <hr className="m-4" />
          {formateurSelected === null || formateurSelected === "" ? (
            <div>
              {reunions === null || reunions.length === 0 ? (
                <h4 className="text-danger">
                  Pas de réunion/conseil sur la période !
                </h4>
              ) : (
                <div className="m-4">
                  <h4>Réunions/conseils de la périodes</h4>
                  <table className="table table-sm table-responsive-md">
                    <thead>
                      <tr>
                        <th>Action</th>
                        <th>Date</th>
                        <th>Objet</th>
                        <th>Participants</th>
                        <th>Nb participants</th>
                        <th>Durée</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reunions.map((r, i) => (
                        <tr key={i}>
                          <td>
                            <FontAwesomeIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setReunionSelected(
                                  JSON.parse(JSON.stringify(r))
                                );
                              }}
                              data-toggle="modal"
                              data-target="#ModalModifReunion"
                              className="text-info"
                              icon="edit"
                            />{" "}
                            &nbsp;{" "}
                            <FontAwesomeIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                if (window.confirm("Supprimer l'absence ?")) {
                                  delReunion(r["@id"]);
                                }
                              }}
                              className="text-danger"
                              icon="trash-alt"
                            />
                          </td>
                          <td>{dateFormated(r.createdAt)}</td>
                          <td>{r.label}</td>
                          <td className="text-left">
                            {r.participants.map((p, i) => (
                              <span
                                className="badge badge-pill badge-info mx-1"
                                key={i}
                                title={p.user.firstName + " " + p.user.name}
                              >
                                {p.user.firstName.slice(0, 1) +
                                  p.user.name.slice(0, 1)}
                              </span>
                            ))}
                          </td>
                          <td>{r.participants.length}</td>
                          <td>{heuresFormH(r.time)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              <hr className="m-4" />
              <h4>Liste des formateurs</h4>
              <div className="d-flex flex-wrap animFadeIn">
                <AllFormateurs />
              </div>
            </div>
          ) : (
            <RelFormateur />
          )}
        </div>
      )}
    </div>
  );
};

export default ReleveHeures;
