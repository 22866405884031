import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../Context/AppContext";
import ProgContext from "../../Context/ProgContext";
import DeleteBddProvider from "../../Providers/DeleteBddProvider";
import GetBddProvider from "../../Providers/GetBddProvider";
import Layout from "../common/Layout";
import MsgAlert from "../common/MsgAlert";
import Spinner from "../common/Spinner";
import EditFastprog from "../contents/edits/EditFastProg";
import EditProgression from "../contents/edits/EditProgression";
import ListProgressions from "../contents/lists/ListProgressions";
import ModifProgression from "../contents/modifs/ModifProgression";
import dateFormated from "../tools/dateFormated";
import { autoCloseMsg } from "../tools/messagesUtils";
import { triByName } from "../tools/sortUtils";

const Progressions = () => {
  const { url, user } = useContext(AppContext);

  const [promos, setPromos] = useState(null);

  const [selected, setSelected] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [promoSelected, setPromoSelected] = useState(null);
  const [matiereSelected, setMatiereSelected] = useState(null);
  const [matieres, setMatieres] = useState([]);
  const [formateur, setFormateur] = useState(null);
  const [actifs, setActifs] = useState(true);
  const [alertValid, setAlertValid] = useState(null);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (promoSelected !== null && promos !== null) {
      setMatieres(null);
      loadMatieres(
        promos.filter((p) => p["@id"] === promoSelected)[0].formation["@id"]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoSelected]);

  const loadMatieres = (promoIri) => {
    let uri = url + "api/matieres?formations=" + promoIri;
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setMatieres(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de chargment des matières", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  useEffect(() => {
    setPromoSelected(null);
    setPromos(null);
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actifs]);

  const maj = () => {
    load();
  };

  const del = (iri) => {
    let uri = url + iri.slice(1);
    DeleteBddProvider(uri).then((res) => {
      if (res === 204) {
        setMsg({
          txt: "La progression est supprimée !",
          type: "success",
        });
        autoCloseMsg(setMsg, 5000);
      } else {
        setMsg({
          txt: "Erreur lors de la suppression",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const contextValue = {
    setSelected,
    del,
    setMsg,
    matieres,
    setSelectedItem,
    setPromoSelected,
    setMatiereSelected,
  };

  const load = () => {
    let uri = url + "api/promos";
    switch (user.role) {
      case 1:
        uri += "?";
        user.sites.forEach((site, i, sites) => {
          uri += sites.length > 1 ? "site[]=" : "site=";
          uri += site["@id"];
          uri += i + 1 < sites.length ? "&" : "";
        });
        if (actifs) {
          uri += "&actif=" + actifs;
        }
        GetBddProvider(uri).then((res) => {
          if (typeof res === "object") {
            setPromos(res["hydra:member"]);
            GetBddProvider(url + "api/formateurs?user=" + user["@id"]).then(
              (res) => {
                if (typeof res === "object") {
                  setFormateur(res["hydra:member"][0]);
                  let today = new Date().toISOString();
                  let uri2 =
                    url +
                    "api/progressions?order[formateur.user.name]=asc&activate=false&createdAt[before]=" +
                    today +
                    "&formateur.id=" +
                    res["hydra:member"][0]["@id"];
                  GetBddProvider(uri2).then((res) => {
                    if (
                      typeof res === "object" &&
                      res["hydra:member"].length > 0
                    ) {
                      setAlertValid(res["hydra:member"]);
                    } else {
                      setAlertValid(null);
                    }
                  });
                } else {
                  // setLoaded(true);
                }
              }
            );
          } else {
            setMsg({ txt: "Erreur de chargement", type: "danger" });
            autoCloseMsg(setMsg, 5000);
          }
        });
        break;
      case 2:
      case 3:
        uri += "?";
        let siteProg = "&";
        user.sites.forEach((site, i, sites) => {
          uri += sites.length > 1 ? "site[]=" : "site=";
          uri += site["@id"];
          uri += i + 1 < sites.length ? "&" : "";

          siteProg += sites.length > 1 ? "promos.site[]=" : "promos.site=";
          siteProg += site["@id"];
          siteProg += i + 1 < sites.length ? "&" : "";
        });
        if (actifs) {
          uri += "&actif=" + actifs;
        }
        GetBddProvider(uri).then((res) => {
          if (typeof res === "object") {
            setPromos(res["hydra:member"]);
            GetBddProvider(url + "api/formateurs?user=" + user["@id"]).then(
              (res) => {
                if (typeof res === "object") {
                  setFormateur(res["hydra:member"][0]);
                  let today = new Date().toISOString();
                  let uri2 =
                    url +
                    "api/progressions?order[formateur.user.name]=asc&activate=false&createdAt[before]=" +
                    today +
                    siteProg;
                  GetBddProvider(uri2).then((res) => {
                    if (
                      typeof res === "object" &&
                      res["hydra:member"].length > 0
                    ) {
                      setAlertValid(res["hydra:member"]);
                    } else {
                      setAlertValid(null);
                    }
                  });
                } else {
                  // setLoaded(true);
                }
              }
            );
          } else {
            setMsg({ txt: "Erreur de chargement", type: "danger" });
            autoCloseMsg(setMsg, 5000);
          }
        });
        break;
      case 3.5:
      case 4:
        GetBddProvider(url + "api/formateurs?user=" + user["@id"]).then(
          (res) => {
            if (typeof res === "object") {
              let result = res["hydra:member"][0];
              let promos = actifs
                ? result.promos.filter((p) => p.actif)
                : result.promos;
              setPromos(promos);
              setFormateur(result);
              let today = new Date().toISOString();
              let uri2 =
                url +
                "api/progressions?order[formateur.user.name]=asc&activate=false&createdAt[before]=" +
                today +
                "&formateur.id=" +
                result["@id"];
              GetBddProvider(uri2).then((res) => {
                if (typeof res === "object" && res["hydra:member"].length > 0) {
                  setAlertValid(res["hydra:member"]);
                } else {
                  setAlertValid(null);
                }
              });
            } else {
              setMsg({ txt: "Erreur de chargement", type: "danger" });
              autoCloseMsg(setMsg, 5000);
            }
          }
        );
        break;
      case 5:
        GetBddProvider(url + "api/eleves?user=" + user["@id"]).then((res) => {
          if (typeof res === "object") {
            let UriEleve = res["hydra:member"][0]["@id"];
            // let tmpPromos = res["hydra:member"][0].promos;
            // let tmp = url + tmpPromos[tmpPromos.length - 1].slice(1);
            let tmp = url + "api/pormos?eleves=" + UriEleve;
            GetBddProvider(tmp).then((res) => {
              if (typeof res === "object") {
                setPromos(res["hydra:member"].filter((p) => p.actif));
              } else {
                setMsg({ txt: "Erreur de chargement", type: "danger" });
                autoCloseMsg(setMsg, 5000);
              }
            });
          } else {
            setMsg({ txt: "Erreur de chargement", type: "danger" });
            autoCloseMsg(setMsg, 5000);
          }
        });
        break;
      case 6:
        GetBddProvider(
          url + "api/eleves?user.actif=true&entreprises.user=" + user["@id"]
        ).then((res) => {
          if (typeof res === "object") {
            let results = res["hydra:member"];
            let temp = [];
            for (const el of results) {
              let UriEleve = el["@id"];
              let tmp = url + "api/pormos?eleves=" + UriEleve;
              // let tmp = el.promos;
              GetBddProvider(tmp).then((res) => {
                if (typeof res === "object") {
                  temp.push(res["hydra:member"].filter((p) => p.actif));
                } else {
                  setMsg({ txt: "Erreur de chargement", type: "danger" });
                  autoCloseMsg(setMsg, 5000);
                }
              });
            }
            setPromos(temp);
            // results.forEach((el, i) => {
            //   // add
            // });
          } else {
            setMsg({ txt: "Erreur de chargement", type: "danger" });
            autoCloseMsg(setMsg, 5000);
          }
        });
        break;
      default:
        setMsg({ txt: "Erreur de profil", type: "danger" });
        break;
    }
  };

  const Content = () => {
    if (promos !== null) {
      return user.sites.length === 1 ? (
        <div>
          <ListProgressions
            promos={promos}
            formateur={formateur}
            promoSelected={promoSelected}
            matiereSelected={matiereSelected}
          />
        </div>
      ) : (
        user.sites.sort(triByName).map((site, i) => {
          let temp = promos.filter((promo) => promo.site.id === site.id);
          return (
            <div key={i}>
              <h3 className="m-3">{site.name}</h3>
              <ListProgressions
                promos={temp}
                siteId={site.id}
                formateur={formateur}
                promoSelected={promoSelected}
                matiereSelected={matiereSelected}
              />
            </div>
          );
        })
      );
    } else {
      return <Spinner />;
    }
  };

  return Layout(
    <div className="mt-4">
      <ProgContext.Provider value={contextValue}>
        {user.role < 5 ? (
          <React.Fragment>
            <ModifProgression selected={selected} maj={maj} />
            <EditProgression currentFormateur={formateur} promos={promos} />
            <EditFastprog selectedItem={selectedItem} formateur={formateur} />
          </React.Fragment>
        ) : null}
        {msg !== null ? (
          <MsgAlert
            msg={msg.txt}
            type={msg.type}
            close={() => autoCloseMsg(setMsg)}
          />
        ) : null}
        <span className="d-flex flex-wrap align-items-center justify-content-between m-3">
          <div>
            <h2>Progressions Pédagogiques</h2>
          </div>
          <div>
            {user.role < 5 ? (
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="chActif"
                  checked={actifs || false}
                  onChange={(e) => setActifs(!actifs)}
                />
                <label className="form-check-label" htmlFor="chActif">
                  <strong>Promos actives uniquement</strong>
                </label>
              </div>
            ) : null}
          </div>
          <div>
            {formateur !== undefined &&
            formateur !== null &&
            formateur["@id"] ? (
              <button
                type="button"
                className="btn btn-sm rounded-pill btn-info"
                data-toggle="modal"
                data-target="#ModalProg"
                onClick={() => {
                  setPromoSelected(null);
                  setMatiereSelected(null);
                }}
              >
                <FontAwesomeIcon icon="plus-circle" /> &nbsp; Ajouter une
                progression
              </button>
            ) : null}
          </div>
        </span>
        {alertValid !== null ? (
          <div className="d-flex justify-content-center p-0 m-4 rounded">
            <div
              className="alert alert-danger accordion p-0 m-0"
              id="accordionAlertPrg"
            >
              <div
                id="headingAlertProg"
                className="m-0 bg-light text-danger p-2 border-0"
                type="button"
                data-toggle="collapse"
                data-target={"#collapseAlertProg"}
                aria-expanded={false}
                aria-controls={"collapseAlertProg"}
              >
                <h2>
                  <FontAwesomeIcon icon="exclamation-triangle" />
                </h2>
                <u>
                  <strong>
                    Il y a {alertValid.length} progression
                    {alertValid.length > 1 ? "s" : ""} non réalisée
                    {alertValid.length > 1 ? "s" : ""} dont la date est
                    antérieure ou égale à aujourd'hui :
                  </strong>
                </u>
                <h5>
                  <FontAwesomeIcon icon="chevron-down" />
                </h5>
              </div>

              <div
                className="collapse"
                id={"collapseAlertProg"}
                aria-labelledby={"headingAlertProg"}
                data-parent={"#accordionAlertPrg"}
              >
                <ul className="list-group text-left">
                  {alertValid.map((el, i) => {
                    return (
                      <li
                        className="list-group-item bg-transparent border-0"
                        style={{ cursor: "pointer" }}
                        onClick={(e) =>
                          setSelected(JSON.parse(JSON.stringify(el)))
                        }
                        data-toggle="modal"
                        data-target="#ModalModifProg"
                        key={i}
                      >
                        {dateFormated(el.createdAt)}{" "}
                        {el.promos.map((p, i) => (
                          <span key={i}>{p.name} </span>
                        ))}
                        {el.progressionItem[0].name}{" "}
                        {user.role <= 3 ? (
                          <span>
                            ({el.formateur.user.name}{" "}
                            {el.formateur.user.firstName})
                          </span>
                        ) : null}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        ) : null}

        <hr className="m-4" />

        <Content />
      </ProgContext.Provider>
    </div>
  );
};

export default Progressions;
