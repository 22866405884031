import React from "react";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import ListExportEleves from "./ListExportEleves";

const ListExportAllPromos = ({ promos, eleveActif, setMsg }) => {
  const filename = `Export Elèves - ${
    promos.length > 1 ? "toutes promos" : promos[0].name
  } ${eleveActif && "- élèves actif"}`;
  return (
    <div>
      <ReactHtmlTableToExcel
        className="btn btn-outline-info btn-sm my-2"
        table="tableEleves"
        filename={filename}
        sheet="Export"
        buttonText="Export Excel"
      />
      <table className="table table-sm table-bordered small" id="tableEleves">
        <thead>
          <tr className="bg-info text-white">
            <th>Promo</th>
            <th>Élève</th>
            <th>Mail élève</th>
            <th>Entreprise</th>
            <th>Tuteur</th>
            <th>ID Tuteur</th>
            <th>Adresse</th>
            <th>CP</th>
            <th>Ville</th>
            <th>Téléphone</th>
            <th>Mail tuteur</th>
            <th className="d-none">Mail admin</th>
            <th className="d-none">Autre e-mail</th>
          </tr>
        </thead>
        {promos.map((promo) => (
          <tbody key={promo["@id"]} className="text-left">
            <ListExportEleves
              promo={promo}
              eleveActif={eleveActif}
              setMsg={setMsg}
            />
          </tbody>
        ))}
      </table>
    </div>
  );
};

export default ListExportAllPromos;
