import React from "react";

export default function ListExportElevesLine({ eleve, promo, tuteur, setMsg }) {
  return (
    <tr>
      <td className="font-weight-bold">{promo.name}</td>
      <td className="font-weight-bold">
        {eleve.user.name} {eleve.user.firstName}
      </td>
      <td>{eleve.user.email}</td>
      {!tuteur ? (
        <>
          <td className="bg-secondary"></td>
          <td className="bg-secondary"></td>
          <td className="bg-secondary"></td>
          <td className="bg-secondary"></td>
          <td className="bg-secondary"></td>
          <td className="bg-secondary"></td>
          <td className="bg-secondary"></td>
          <td className="bg-secondary"></td>
          <td className="bg-secondary d-none"></td>
          <td className="bg-secondary d-none"></td>
        </>
      ) : (
        <>
          <td className="font-weight-bold">{tuteur.raisonSociale}</td>
          <td className="font-weight-bold">
            {tuteur?.user.name} {tuteur?.user.firstName}
          </td>
          <td className="font-weight-bold">{tuteur?.user.pseudo}</td>
          <td>{tuteur?.adresse}</td>
          <td>{tuteur?.cp}</td>
          <td>{tuteur?.ville}</td>
          <td>{tuteur?.tel}</td>
          <td>{tuteur?.user.email}</td>
          <td className="d-none">{tuteur.mailAdmin}</td>
          <td className="d-none">{tuteur.mailAdmin2}</td>
        </>
      )}
    </tr>
  );
}
