import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../Context/AppContext";
import DeleteBddProvider from "../../Providers/DeleteBddProvider";
import GetBddProvider from "../../Providers/GetBddProvider";
import Layout from "../common/Layout";
import MsgAlert from "../common/MsgAlert";
import Spinner from "../common/Spinner";
import SmecBetaModal from "../contents/Beta/SmecBetaModal";
import EditSMEC from "../contents/edits/EditSMEC";
import EditSuiviComp from "../contents/edits/EditSuiviComp";
import ListSmecs from "../contents/lists/ListSmecs";
import ListSuivis from "../contents/lists/ListSuivis";
import ViewSMEC from "../contents/views/ViewSMEC";
import ViewSuiviComp from "../contents/views/ViewSuiviComp";
import { autoCloseMsg } from "../tools/messagesUtils";
import { triByName, triByUserName } from "../tools/sortUtils";

const SuiviCompetences = () => {
  const { url, user } = useContext(AppContext);

  const toDay = new Date();
  const initDebut = new Date(toDay.getFullYear(), toDay.getMonth(), 1);
  const initFin = new Date(toDay.getFullYear(), toDay.getMonth() + 1, 0);

  const [debut, setDebut] = useState(
    new Date(
      initDebut.getFullYear(),
      initDebut.getMonth(),
      initDebut.getDate(),
      -initDebut.getTimezoneOffset() / 60
    )
      .toISOString()
      .split("T")[0]
  );
  const [fin, setFin] = useState(
    new Date(
      initFin.getFullYear(),
      initFin.getMonth(),
      initFin.getDate(),
      -initFin.getTimezoneOffset() / 60
    )
      .toISOString()
      .split("T")[0]
  );

  const [promos, setPromos] = useState(null);
  const [listPromos, setListPromos] = useState([]);
  const [promoSelected, setPromoSelected] = useState(null);
  const [eleveSelected, setEleveSelected] = useState(null);
  const [elevesloading, setElevesloading] = useState(null);
  const [eleves, setEleves] = useState(null);
  const [suivis, setSuivis] = useState(null);
  const [smecs, setSmecs] = useState(null);
  const [flow, setFlow] = useState(false);
  const [suiviSelected, setSuiviSelected] = useState(null);
  const [smecSelected, setSmecSelected] = useState(null);
  const [sites, setSites] = useState([]);
  const [siteSelected, setSiteSelected] = useState(user.sites[0]["@id"]);
  const [reload, setReload] = useState(false);
  const [msg, setMsg] = useState(null);
  const [maj, setMaj] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [actif, setActif] = useState(true);

  useEffect(() => {
    load();
    loadSites();
    loadSuivis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    load();
    setMaj(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteSelected]);

  useEffect(() => {
    if (maj) {
      setMaj(false);
      setSuivis(null);
      loadSuivis();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maj]);

  useEffect(() => {
    if (reload) {
      setPromoSelected(null);
      setEleveSelected(null);
      setFlow(false);
      setEleves(null);
      setSuivis(null);
      setLoaded(false);
      load();
      loadSuivis();
      setReload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  useEffect(() => {
    setEleves(null);
    setEleveSelected(null);
    setElevesloading(true);
    if (promoSelected !== null && promoSelected !== "") {
      loadEleves(promoSelected);
    } else {
      setElevesloading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoSelected]);

  useEffect(() => {
    if (actif) {
      setListPromos(promos ? promos.filter((p) => p.actif) : []);
    } else {
      setListPromos(promos || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actif]);

  const loadSites = () => {
    if (user.role > 1) {
      setSites(user.sites.sort(triByName));
    } else {
      let uri = url + "api/sites";
      GetBddProvider(uri).then((res) => {
        if (typeof res === "object") {
          setSites(res["hydra:member"].sort(triByName));
        } else {
          setMsg({ txt: "Erreur de Chargement des sites", type: "danger" });
          autoCloseMsg(setMsg, 5000);
        }
      });
    }
  };

  const load = () => {
    let uri = `${url}api/promos?site=${siteSelected}`;
    GetBddProvider(uri).then((res) => {
      setLoaded(true);
      if (typeof res === "object") {
        let result = res["hydra:member"];
        setPromos(result);
        setListPromos(actif ? result.filter((r) => r.actif) : result);
      } else {
        setMsg({ txt: "Erreur de Chargement des promos", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadEleves = () => {
    let uri = url + "api/eleves?promos=" + promoSelected;
    GetBddProvider(uri).then((res) => {
      setElevesloading(false);
      if (typeof res === "object") {
        setEleves(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de Chargement des élèves", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadSuivis = () => {
    let uri2 = `${url}api/eval_afests?order[createdAt]=asc&createdAt[after]=${debut}&createdAt[before]=${fin}T23:59:59`;
    let uri = `${url}api/eval_competences?order[createdAt]=asc&createdAt[after]=${debut}&createdAt[before]=${fin}`;
    if (eleveSelected !== null) {
      uri += `&eleve=${eleveSelected}`;
      uri2 += `&eleve=${eleveSelected}`;
    }
    if (promoSelected === null || promoSelected === "") {
      uri += `&promo.site=${siteSelected}`;
      uri2 += `&promo.site=${siteSelected}`;
    } else {
      uri += `&promo=${promoSelected}`;
      uri2 += `&promo=${promoSelected}`;
    }
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setSuivis(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de chargement des suivis", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
    GetBddProvider(uri2).then((res) => {
      if (typeof res === "object") {
        setSmecs(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de chargement des suivis", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const delSuivi = (iri) => {
    setSuivis(null);
    setSmecs(null);
    let uri = url + iri.slice(1);
    DeleteBddProvider(uri).then((res) => {
      loadSuivis();
      if (res === 204) {
        setMsg({
          txt: "Le suivi a été supprimée !",
          type: "success",
        });
        autoCloseMsg(setMsg, 5000);
      } else {
        setMsg({
          txt: "Erreur lors de la suppression",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  return Layout(
    <div className="mt-4">
      <div className="d-flex flex-column flex-xl-row flex-wrap justify-content-md-between justify-content-center mx-4">
        <h2>Gestion des suivis de compétences / SMEC</h2>
        <div className="form-row align-items-center justify-content-center mx-2">
          <div className="my-2">
            <select
              className="form-control rounded-pill"
              value={siteSelected || ""}
              onChange={(e) => {
                setSiteSelected(e.target.value);
                setReload(true);
                setLoaded(false);
              }}
            >
              {sites !== null
                ? sites.map((s, i) => (
                    <option value={s["@id"]} key={i}>
                      {s.name}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <div className="form-check ml-4 my-2">
            <input
              type="checkbox"
              className="form-check-input"
              id="checkSuiviActif"
              checked={actif}
              onChange={() => setActif(!actif)}
            />
            <label className="form-check-label" htmlFor="checkSuiviActif">
              <strong>Promos actives</strong>
            </label>
          </div>
        </div>
        <button
          className="btn rounded-pill btn-info align-self-center"
          data-toggle="modal"
          data-target="#ModalSaisiAfest"
        >
          <FontAwesomeIcon icon="edit" /> Saisir un suivi
        </button>
      </div>
      <EditSuiviComp setMaj={setMaj} listPromos={listPromos} />
      <ViewSuiviComp suiviSelected={suiviSelected} setMaj={setMaj} />
      <SmecBetaModal
        promos={listPromos}
        setMaj={setMaj}
        setMsg={setMsg}
        site={siteSelected}
      />
      <ViewSMEC suiviSelected={smecSelected} />
      <EditSMEC setMaj={setMaj} smec={smecSelected} />
      <hr className="m-4" />
      {msg !== null ? (
        <MsgAlert
          msg={msg.txt}
          type={msg.type}
          close={() => autoCloseMsg(setMsg)}
        />
      ) : null}
      {!loaded || promos === null ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <div className="form-row mx-4 align-items-center">
            <div className="form-group col-lg-4">
              <label>Promo</label>
              <select
                className="form-control rounded-pill"
                value={promoSelected || ""}
                onChange={(e) => {
                  setPromoSelected(e.target.value);
                }}
              >
                <option value="">Toutes les promos</option>
                {listPromos.map((p, i) => (
                  <option value={p["@id"]} key={i}>
                    {p.site.name} - {p.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group col-lg-5">
              <label>Eleves</label>
              <select
                className="form-control rounded-pill"
                value={eleveSelected || ""}
                onChange={(e) => setEleveSelected(e.target.value)}
                disabled={eleves === null}
              >
                <option value="">
                  {elevesloading
                    ? "Chargement en cours ..."
                    : eleves === null
                    ? "Veuillez sélectionner une promo"
                    : eleves.length < 1
                    ? "Pas d'élèves"
                    : "Tous les élèves"}
                </option>
                {eleves === null
                  ? null
                  : eleves.sort(triByUserName).map((el, i) => (
                      <option value={el["@id"]} key={i}>
                        {el.user.name} {el.user.firstName}
                      </option>
                    ))}
              </select>
            </div>
            <div className="form-group col-lg-3">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="switchFilDeLEauList"
                  checked={flow || false}
                  onChange={() => setFlow((prev) => !prev)}
                />
                <label
                  className="custom-control-label"
                  htmlFor="switchFilDeLEauList"
                >
                  <strong>Fil de l'eau</strong>
                </label>
              </div>
            </div>
          </div>
          <div className="form-row mx-4">
            <div className="form-group col-lg-5">
              <label>
                Début de période{" "}
                {navigator.userAgent.indexOf("Safari") >= 1 &&
                navigator.userAgent.indexOf("Chrome") <= 0 ? (
                  <em>
                    <small> (aaaa-mm-jj)</small>
                  </em>
                ) : null}
              </label>
              <input
                className="form-control rounded-pill"
                type="date"
                placeholder={
                  navigator.userAgent.indexOf("Safari") >= 1 &&
                  navigator.userAgent.indexOf("Chrome") <= 0
                    ? "aaaa-mm-jj"
                    : null
                }
                pattern={
                  navigator.userAgent.indexOf("Safari") >= 1 &&
                  navigator.userAgent.indexOf("Chrome") <= 0
                    ? "\\d{4}-\\d{2}-\\d{2}"
                    : null
                }
                value={debut || ""}
                onChange={(e) => setDebut(e.target.value)}
                required
              />
            </div>
            <div className="form-group col-lg-5">
              <label>
                Fin de période{" "}
                {navigator.userAgent.indexOf("Safari") >= 1 &&
                navigator.userAgent.indexOf("Chrome") <= 0 ? (
                  <em>
                    <small> (aaaa-mm-jj)</small>
                  </em>
                ) : null}
              </label>
              <input
                className="form-control rounded-pill"
                type="date"
                placeholder={
                  navigator.userAgent.indexOf("Safari") >= 1 &&
                  navigator.userAgent.indexOf("Chrome") <= 0
                    ? "aaaa-mm-jj"
                    : null
                }
                pattern={
                  navigator.userAgent.indexOf("Safari") >= 1 &&
                  navigator.userAgent.indexOf("Chrome") <= 0
                    ? "\\d{4}-\\d{2}-\\d{2}"
                    : null
                }
                value={fin || ""}
                onChange={(e) => setFin(e.target.value)}
                required
              />
            </div>
            <div className="form-group col-lg-2 align-self-end">
              <button
                className="btn btn-sm btn-outline-primary form-control rounded-pill"
                onClick={() => {
                  setSuivis(null);
                  loadSuivis();
                }}
              >
                Afficher
              </button>
            </div>
          </div>
          <hr className="m-4" />
          <div className="m-4 animFadeIn">
            {suivis === null || smecs === null ? (
              <Spinner />
            ) : (
              <>
                {suivis.length === 0 && smecs.length === 0 && (
                  <h4 className="text-danger m-4">
                    <strong>Pas de suivi sur la période</strong>
                  </h4>
                )}
                {suivis.length > 0 && (
                  <ListSuivis
                    suivis={suivis}
                    delSuivi={delSuivi}
                    debut={debut}
                    setSuiviSelected={setSuiviSelected}
                  />
                )}
                {smecs.length > 0 && (
                  <ListSmecs
                    smecs={flow ? smecs.filter((s) => s.flow) : smecs}
                    delSmec={delSuivi}
                    debut={debut}
                    setSmecSelected={setSmecSelected}
                  />
                )}
              </>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default SuiviCompetences;
