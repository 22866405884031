import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import PostBddProvider from "../../../Providers/PostBddProvider";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import GenPass from "../../GenPass";
import MsgAlert from "../../common/MsgAlert";
import SmallSpinner from "../../common/SmallSpinner";
import { autoCloseMsg } from "../../tools/messagesUtils";
import TuteurGalia from "./TuteurGalia";

function EleveItemGalia({
  student,
  listIdGalia,
  site,
  promo,
  elevesList,
  maj,
}) {
  const { url } = useContext(AppContext);

  const assign = elevesList.filter((e) => e.idGalia === student.ID);

  const [eleveSelected, setEleveSelected] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [save, setSave] = useState(true);
  const [saveMaj, setSaveMaj] = useState(true);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    preselectEleve();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (eleveSelected === "create") {
      setConfirm(true);
    }
  }, [eleveSelected]);

  const preselectEleve = () => {
    let eleve = elevesList.filter(
      (e) => e.user.actif && e.user.name === student.Stagiaire.Nom
    );
    setEleveSelected(eleve.length !== 0 ? eleve[0]["@id"] : null);
  };

  const saveSync = () => {
    setSave(false);
    let uri = url + eleveSelected.slice(1);
    let data = { idGalia: student.ID, idStagiaire: student.Stagiaire.ID };
    update(uri, data);
  };

  const supSync = () => {
    setSave(false);
    let uri = url + assign[0]["@id"].slice(1);
    let data = { idGalia: null, idStagiaire: null };
    update(uri, data);
  };

  const saveStagiaire = () => {
    setSaveMaj(false);
    let uri = url + assign[0]["@id"].slice(1);
    let data = { idStagiaire: student.Stagiaire.ID };
    update(uri, data);
  };

  const majMail = () => {
    setSaveMaj(false);
    let uri = url + assign[0].user["@id"].slice(1);
    let data = { email: student.Stagiaire.Email_Pro };
    update(uri, data);
  };

  const create = async () => {
    setSave(false);
    let temp = {
      idGalia: student.ID,
      idStagiaire: student.Stagiaire.ID,
      promos: [promo["@id"]],
      user: {
        email:
          student.Stagiaire.Email_Pro !== null &&
          student.Stagiaire.Email_Pro !== ""
            ? student.Stagiaire.Email_Pro
            : student.Stagiaire.Email_Perso,
        roles: ["ROLE_ELEVE"],
        name: student.Stagiaire.Nom,
        firstName: student.Stagiaire.Prenom,
        sites: [site["@id"]],
        actif: true,
        validate: false,
        pseudo: `${student.Stagiaire.Prenom.toLowerCase().slice(
          0,
          1
        )}.${student.Stagiaire.Nom.toLowerCase()}`,
      },
    };
    for (let ok = false; ok === false; ) {
      let pseudoTest = temp.user.pseudo.slice(-1);
      pseudoTest === " "
        ? (temp.user.pseudo = temp.user.pseudo.slice(0, -1))
        : (ok = true);
    }
    temp.user.password = GenPass();
    let saved = false;
    for (let variation = 0; saved === false; variation++) {
      let uri = url + "api/users";
      variation > 0 && (temp.user.pseudo = temp.user.pseudo + variation);
      saved = await PostBddProvider(uri, temp.user).then((res) => {
        if (typeof res === "object" && res.id) {
          uri = url + "api/eleves";
          let data = {
            ...temp,
            user: "/api/users/" + res.id,
          };
          uri = url + "api/eleves";
          return PostBddProvider(uri, data).then((res) => {
            if (typeof res === "object" && res.id) {
              setMsg({
                txt: "l'élève à bien été enregistrée",
                type: "success",
              });
              autoCloseMsg(setMsg, 10000);
              setSave(true);
              maj(true);
            } else {
              let txt = res.violations
                ? res.violations.map((violation, i) => {
                    return <div key={i}>- {violation.message}</div>;
                  })
                : "Erreur";
              setSave(true);
              setMsg({ txt: txt, type: "danger" });
              autoCloseMsg(setMsg, 10000);
            }
            return true;
          });
        } else {
          let txt = "Erreur ";
          if (res.violations) {
            let violations = res.violations;
            if (
              violations.filter(
                (v) => v.message === "Nom d'utilisateur déjà utilisé."
              ).length > 0
            ) {
              return false;
            }
            res.violations.forEach((v) => (txt += " - " + v.message));
          }
          setSave(true);
          setEleveSelected(null);
          setMsg({ txt: txt, type: "danger" });
          autoCloseMsg(setMsg, 10000);
          return true;
        }
      });
    }
  };

  const update = (uri, data) => {
    UpdateBddProvider(uri, data).then((res) => {
      if (typeof res === "object" && res.id) {
        setMsg({
          txt: "les données ont bien été modifiées",
          type: "success",
        });
        autoCloseMsg(setMsg, 10000);
        setSave(true);
        setSaveMaj(true);
        maj(true);
      } else {
        setSave(true);
        setMsg({ txt: "Erreur d'enregistrement", type: "danger" });
        autoCloseMsg(setMsg, 10000);
      }
    });
  };

  const msgConfirmation = (
    <>
      Confirmez la création de l'élève ? <br />
      <small>
        <em>
          (Si l'élève était présent l'année précédente, veuillez ajouter sa
          nouvelle promo dans sa fiche élève avant de faire la synchronisation)
        </em>
      </small>
      <div>
        <button
          type="button"
          className="btn btn-success btn-sm mr-2"
          onClick={() => {
            if (
              student.Stagiaire.Email_Perso !== "" &&
              student.Stagiaire.Email_Perso !== null &&
              student.Stagiaire.Email_Pro !== "" &&
              student.Stagiaire.Email_Pro !== null
            ) {
              setConfirm(false);
              create();
            } else {
              setMsg({
                txt: "l'adresse e-mail de l'étudiant est requis",
                type: "warning",
              });
              autoCloseMsg(setMsg, 10000);
            }
          }}
        >
          Créer
        </button>
        <button
          type="button"
          className="btn btn-danger btn-sm"
          onClick={() => {
            setEleveSelected(null);
            setConfirm(false);
          }}
        >
          Annuler
        </button>
      </div>
    </>
  );

  return (
    <li className="list-group-item">
      {msg !== null && (
        <MsgAlert
          msg={msg.txt}
          type={msg.type}
          close={() => autoCloseMsg(setMsg)}
        />
      )}
      {confirm && (
        <MsgAlert
          msg={msgConfirmation}
          type="warning p-2"
          close={() => {
            setEleveSelected(null);
            setConfirm(false);
          }}
        />
      )}
      <h6>
        {student.Stagiaire.Nom} {student.Stagiaire.Prenom} <br />
        <em className="text-info">
          <small>
            <strong>Email_Perso : </strong>
            {student.Stagiaire.Email_Perso !== "" &&
            student.Stagiaire.Email_Perso !== null ? (
              student.Stagiaire.Email_Perso
            ) : (
              <span className="text-warning">
                pas de mail perso dans Galia !
              </span>
            )}
            <br />
            <strong>Email_Pro : </strong>
            {student.Stagiaire.Email_Pro !== "" &&
            student.Stagiaire.Email_Pro !== null ? (
              student.Stagiaire.Email_Pro
            ) : (
              <span className="text-warning">pas de mail pro dans Galia !</span>
            )}
          </small>
        </em>
      </h6>
      <div className="form-inline">
        <FontAwesomeIcon
          icon="sync-alt"
          className={`mr-2 ${
            assign.length !== 0 ? "text-success" : "text-warning"
          }`}
        />
        {assign.length === 0 ? (
          <>
            <select
              className="form-control"
              value={eleveSelected || ""}
              onChange={(e) => setEleveSelected(e.target.value)}
            >
              <option value="" disabled>
                Sélectionnez un élève
              </option>
              <option value="create">Créer l'élève</option>
              {elevesList
                .filter((e) => !e.idGalia || !listIdGalia.includes(e.idGalia))
                .map((eleve) => (
                  <option
                    value={eleve["@id"]}
                    className={!eleve.user.actif ? "text-danger" : ""}
                    key={`${student.ID}${eleve["@id"]}`}
                  >
                    {eleve.user.name} {eleve.user.firstName}{" "}
                    {!eleve.user.actif && "(inactif)"}
                  </option>
                ))}
            </select>
            <button
              className="btn btn-outline-success btn-sm ml-2"
              title="enregistrer"
              onClick={() => {
                saveSync();
              }}
              disabled={eleveSelected === null || eleveSelected === "create"}
            >
              {save ? <FontAwesomeIcon icon="check" /> : <SmallSpinner />}
            </button>
          </>
        ) : (
          <>
            <span className={!assign[0].user.actif ? "text-danger" : ""}>
              {assign[0].user.name} {assign[0].user.firstName}{" "}
              {!assign[0].user.actif && "(inactif)"}
            </span>

            <button
              className="btn btn-outline-danger btn-sm ml-2"
              title="Désynchroniser"
              onClick={() => supSync()}
            >
              {save ? (
                <FontAwesomeIcon icon="times" role="button" />
              ) : (
                <SmallSpinner />
              )}
            </button>
            {!assign[0].idStagiaire && (
              <button
                className="btn btn-warning btn-sm ml-2"
                onClick={saveStagiaire}
              >
                {saveMaj ? "MAJ" : <SmallSpinner />}
              </button>
            )}
            {student.Stagiaire.Email_Pro !== "" &&
              student.Stagiaire.Email_Pro !== null &&
              assign[0].user.email !== student.Stagiaire.Email_Pro && (
                <button
                  className="btn btn-warning btn-sm ml-2"
                  onClick={majMail}
                >
                  {saveMaj ? "MAJ mail" : <SmallSpinner />}
                </button>
              )}
          </>
        )}
      </div>
      {assign.length > 0 && (
        <TuteurGalia
          student={student}
          initEleve={assign[0]}
          site={site["@id"]}
          setMsg={setMsg}
        />
      )}
    </li>
  );
}

export default EleveItemGalia;
