import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../Context/AppContext";
import GetBddProvider from "../../Providers/GetBddProvider";
import Layout from "../common/Layout";
import MsgAlert from "../common/MsgAlert";
import Spinner from "../common/Spinner";
import dateFormated from "../tools/dateFormated";
import { autoCloseMsg } from "../tools/messagesUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DeleteBddProvider from "../../Providers/DeleteBddProvider";
import { EditSanction } from "../contents/edits/EditSanction";
import { generatePdf } from "../tools/SanctionPDF";
import { triByEleveUserName } from "../tools/sortUtils";

const GestSanction = () => {
  const { url, user, urlPdf } = useContext(AppContext);

  const safari =
    navigator.userAgent.indexOf("Safari") >= 1 &&
    navigator.userAgent.indexOf("Chrome") <= 0
      ? true
      : false;
  const [debut, setDebut] = useState(null);
  const [fin, setFin] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [msg, setMsg] = useState(null);
  const [motifs, setMotifs] = useState([]);
  const [motifSelected, setMotifSelected] = useState(null);
  const [sanctions, setSanctions] = useState([]);
  const [sanctionSelected, setSanctionSelected] = useState({});
  const [majSanctions, setMajSanctions] = useState(false);
  const [promos, setPromos] = useState([]);
  const [promoSelected, setPromoSelected] = useState(null);
  const [site, setSite] = useState(user.sites[0]["@id"]);
  const [actif, setActif] = useState(true);

  useEffect(() => {
    init();
    loadPromo();
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (site) {
      loadPromo();
      motifs.length > 0 && loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  useEffect(() => {
    loadPromo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actif]);

  useEffect(() => {
    if (motifs.length > 0) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [motifs]);

  useEffect(() => {
    if (majSanctions) {
      setMajSanctions(false);
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [majSanctions]);

  const init = () => {
    let tmp = new Date();
    setDebut(
      new Date(
        tmp.getFullYear(),
        tmp.getMonth(),
        1,
        -tmp.getTimezoneOffset() / 60
      )
        .toISOString()
        .split("T")[0]
    );
    setFin(
      new Date(
        tmp.getFullYear(),
        tmp.getMonth() + 1,
        0,
        -tmp.getTimezoneOffset() / 60
      )
        .toISOString()
        .split("T")[0]
    );
  };

  const loadPromo = () => {
    let uri = url + "api/promos?";
    uri += `site=${site}`;
    // user.sites.forEach((site, i, sites) => {
    //   uri += sites.length > 1 ? "site[]=" : "site=";
    //   uri += site["@id"];
    //   uri += i + 1 < sites.length ? "&" : "";
    // });
    if (actif) {
      uri += `&actif=${actif}`;
    }
    GetBddProvider(uri).then((res) => {
      setLoaded(true);
      if (typeof res === "object") {
        setPromos(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de Chargement des promos", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const load = () => {
    let uri = url + "api/sanction_motifs?";
    GetBddProvider(uri).then((res) => {
      setLoaded(true);
      if (typeof res === "object") {
        setMotifs(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de Chargement des motifs", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadData = () => {
    // Création des uri
    let uriSanctions =
      url +
      "api/sanctions?createdAt[after]=" +
      debut +
      "&createdAt[before]=" +
      fin;
    if (motifSelected !== null && motifSelected !== "") {
      uriSanctions += "&motif=" + motifSelected;
    }
    uriSanctions += "&semestre.promo.site=" + site;
    // user.sites.forEach((site, i, sites) => {
    //   uriSanctions += sites.length > 1 ? "&semestre.promo.site[]=" : "&semestre.promo.site=";
    //   uriSanctions += site["@id"];
    // });
    if (promoSelected !== null && promoSelected !== "") {
      let promo = promos.find((p) => p["@id"] === promoSelected);
      if (promo && promo.semestres?.length > 0) {
        promo.semestres.forEach((s) => {
          uriSanctions += "&semestre[]=" + s["@id"];
        });
      }
    }

    // Chargement des élèves
    GetBddProvider(uriSanctions).then((res) => {
      if (typeof res === "object") {
        setSanctions(res["hydra:member"].sort(triByEleveUserName));
      } else {
        setMsg({ txt: "Erreur de Chargement des évènements", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
      setLoaded(true);
    });
  };

  const deleteSanction = (uri) => {
    DeleteBddProvider(`${url}${uri.slice(1)}`).then((res) => {
      if (res === 204) {
        loadData();
        setMsg({ txt: "Sanction supprimée", type: "success" });
        autoCloseMsg(setMsg, 5000);
      } else {
        setMsg({ txt: "Erreur de chargement des sanctions", type: "danger" });
      }
    });
  };

  return Layout(
    <div className="mt-4">
      {user.role < 4 && (
        <EditSanction
          sanction={sanctionSelected}
          msg={msg}
          setMsg={setMsg}
          setMaj={setMajSanctions}
          // semestres={promo.semestres}
          motifs={motifs}
        />
      )}
      <h2>Liste des évènements</h2>
      <div className="form-group d-flex justify-content-center">
        <select
          name="selectSite"
          className="form-control w-auto"
          value={site || ""}
          onChange={(e) => setSite(e.target.value)}
          required
        >
          <option value="" disabled>
            Sélectionnez un centre
          </option>
          {user.sites.map((s, i) => (
            <option value={s["@id"]} key={i}>
              {s.name}
            </option>
          ))}
        </select>
      </div>
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          checked={actif || false}
          onChange={(e) => setActif(!actif)}
        />
        <label className="form-check-label">
          <strong>Promos actives uniquement</strong>
        </label>
      </div>
      {msg !== null ? (
        <MsgAlert
          msg={msg.txt}
          type={msg.type}
          close={() => autoCloseMsg(setMsg)}
        />
      ) : null}
      <hr className="m-4" />
      {!loaded ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <div className="form-row justify-content-center  mx-4">
            <div className="form-group col-lg-6">
              <label>Promos</label>
              <select
                className="form-control rounded-pill"
                value={promoSelected || ""}
                onChange={(e) => {
                  setPromoSelected(e.target.value);
                }}
              >
                <option value="">Toutes les promos</option>
                {promos.map((p, i) => (
                  <option value={p["@id"]} key={i}>
                    {p.site.name} - {p.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group col-lg-6">
              <label>Type</label>
              <select
                className="form-control rounded-pill"
                value={motifSelected || ""}
                onChange={(e) => {
                  setMotifSelected(e.target.value);
                }}
              >
                <option value="">Tous les motifs</option>
                {motifs.map((m, i) => (
                  <option value={m["@id"]} key={i}>
                    {m.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group col-lg-3">
              <label>
                Début de période{" "}
                {safari ? (
                  <em>
                    <small> (aaaa-mm-jj)</small>
                  </em>
                ) : null}
              </label>
              <input
                type="date"
                className="form-control rounded-pill"
                placeholder={safari ? "aaaa-mm-jj" : null}
                pattern={safari ? "\\d{4}-\\d{2}-\\d{2}" : null}
                value={debut || ""}
                onChange={(e) => setDebut(e.target.value)}
                required
              />
            </div>
            <div className="form-group col-lg-3">
              <label>
                Fin de période{" "}
                {safari ? (
                  <em>
                    <small> (aaaa-mm-jj)</small>
                  </em>
                ) : null}
              </label>
              <input
                type="date"
                className="form-control rounded-pill"
                placeholder={safari ? "aaaa-mm-jj" : null}
                pattern={safari ? "\\d{4}-\\d{2}-\\d{2}" : null}
                value={fin || ""}
                onChange={(e) => setFin(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="form-row mx-4 justify-content-center">
            <div className="form-group col-lg-2">
              <button
                className="btn btn-sm rounded-pill btn-outline-primary form-control"
                onClick={() => {
                  setLoaded(false);
                  loadData();
                }}
              >
                Afficher
              </button>
            </div>
          </div>
          <hr className="m-4" />
          <div className="m-4">
            {sanctions.length < 1 ? (
              <h4>Pas de évènements</h4>
            ) : (
              <React.Fragment>
                <div className="d-flex justify-content-between">
                  <h4>Liste des sanctions</h4>
                  <ReactHTMLTableToExcel
                    className="btn btn-sm rounded-pill btn-outline-primary"
                    table="TableSanctions"
                    filename={"relevé-sanctions"}
                    sheet="Export"
                    buttonText="Export Excel"
                  />
                </div>
                <div className="table-responsive mt-4">
                  <table
                    className="table table-bordered table-sm"
                    id="TableSanctions"
                  >
                    <thead>
                      <tr className="bg-info text-white">
                        <th className="align-middle">Date</th>
                        <th className="align-middle">Élève</th>
                        <th className="align-middle">Promo</th>
                        <th className="align-middle">Auteur</th>
                        <th className="align-middle">Motif</th>
                        <th className="align-middle">Commentaires</th>
                        <th className="align-middle">Notes</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sanctions.map((s, i) => {
                        let promo = promos.find((p) =>
                          p.semestres.find((sem) => sem["@id"] === s.semestre)
                        );
                        return (
                          <tr key={i}>
                            <td className="text-left font-weight-bold align-middle">
                              <div>{dateFormated(s.createdAt)}</div>
                              <div className="text-center">
                                <FontAwesomeIcon
                                  className="text-primary mr-2"
                                  style={{ cursor: "pointer" }}
                                  data-toggle="modal"
                                  data-target="#ModalEditSanction"
                                  title="Modifier l'évènement'"
                                  onClick={() =>
                                    setSanctionSelected({
                                      ...s,
                                      eleve: s.eleve["@id"],
                                      author: s.author["@id"],
                                      motif: s.motif["@id"],
                                      createdAt: s.createdAt.split("T")[0],
                                    })
                                  }
                                  icon="edit"
                                />
                                <FontAwesomeIcon
                                  className="text-danger mr-2"
                                  style={{ cursor: "pointer" }}
                                  title="Supprimer l'évènement"
                                  onClick={() =>
                                    window.confirm(
                                      "Voulez-vous supprimer cet'évènement ?"
                                    ) && deleteSanction(s["@id"])
                                  }
                                  icon="trash-alt"
                                />
                                <FontAwesomeIcon
                                  className="text-success mr-2"
                                  style={{ cursor: "pointer" }}
                                  title="Générer le pdf"
                                  onClick={() =>
                                    generatePdf(
                                      s,
                                      s.eleve,
                                      user.sites.find((s) => s["@id"] === site),
                                      urlPdf
                                    )
                                  }
                                  icon="print"
                                />
                              </div>
                            </td>
                            <td className="font-weight-bold text-nowrap align-middle">
                              {s.eleve.user.firstName} {s.eleve.user.name}
                            </td>
                            <td className="font-weight-bold text-nowrap text-primary align-middle">
                              {promo ? promo.name : ""}
                            </td>
                            <td className="align-middle">
                              {s.author.firstName} {s.author.name}
                            </td>
                            <td className="align-middle">{s.motif.name}</td>
                            <td className="align-middle">
                              {s.comment.split("\n").map((c, i) => (
                                <React.Fragment key={i}>
                                  {c}
                                  <br />
                                </React.Fragment>
                              ))}
                            </td>
                            <td className="text-info align-middle">
                              {s.notes &&
                                s.notes.split("\n").map((c, i) => (
                                  <React.Fragment key={i}>
                                    {c}
                                    <br />
                                  </React.Fragment>
                                ))}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default GestSanction;
